@import "../../scss/custom";

.removeButtonStyle {
  display: inline-block;
  border: none;
  padding: 0.5rem 0rem;
  margin: 0;
  text-decoration: none;
  background: transparent;
  color: #ffffff;
  font-family: sans-serif;
  font-size: 1rem;
  line-height: 1;
  cursor: pointer;
  text-align: center;
  transition: background 250ms ease-in-out, transform 150ms ease;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.linkOutButton {
  display: inline-block;
  border: 2px solid white;
  border-radius: 1rem;
  margin-right: 1rem;
  text-decoration: none;
  color: #ffffff;
  font-family: sans-serif;
  font-size: 0.75rem;
  line-height: 1;
  cursor: pointer;
  text-align: center;
  transition: background 250ms ease-in-out, transform 150ms ease;
}

.linkOutWDC {
  background-color: $danger;
}

.linkOutWCC {
  background-color: $success;
}
