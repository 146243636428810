@import "/src/scss/custom";

.constant-width {
  width: 60px;
}

.white-text {
  color: white;
}

.white-text:disabled {
  color: white;
}

.redbull-bg {
  background-color: $redbull-color;
}

.redbull-bg:disabled {
  background-color: $redbull-color;
}

.mercedes-bg {
  background-color: $mercedes-color;
}

.mercedes-bg:disabled {
  background-color: $mercedes-color;
}
